<template>
    <div class="box">
        <el-carousel height="464px">
            <el-carousel-item v-for="(item, index) in slideshowList" :key="index">
                <img class="lbt" :src="item.picUrl" alt="" />
            </el-carousel-item>
        </el-carousel>
        <!--        <myBreadcrumb :nav="nav"></myBreadcrumb>-->
        <div class="row">
            <div class="left">
                <div class="col">
                    <span class="type">预约类型：</span>
                    <el-select v-model="type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
            <div class="left">
                <div class="col">
                    <span class="type">排序：</span>
                    <el-select v-model="type" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="item" v-for="(item, idx) in list" :key="idx">
                <img class="active" :src="item.picUrl" alt="" />
                <div class="info">
                    <div class="title">{{ item.libName }}</div>
                    <div class="address">
                        <span>使用面积：{{ item.usableArea }}平</span>
                    </div>
                    <div class="address">
                        <span>可容纳人数：{{ item.capacityNum }}人</span>
                    </div>
                </div>
            </div>
        </div>
        <el-pagination class="pagination" v-if="total" :current-page="pageNum" :page-size="pageSize" :total="total" @current-change="currentChange" background layout="prev, pager, next,jumper"></el-pagination>
    </div>
</template>

<script>
import { getSlideshowList } from '@/api';
export default {
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '活动',
                },
                {
                    title: '场馆预约',
                },
            ],
            options: [
                {
                    value: '',
                    label: '全部',
                },
                {
                    label: '参观游玩',
                    value: 0,
                },
                {
                    label: '团体使用',
                    value: 1,
                },
            ],
            slideshowList: [],
            type: '',
            searchValue: '',
            pageNum: 1,
            pageSize: 16,
            total: 0,
            list: [],
        };
    },
    created() {
        this.getData();
        getSlideshowList().then((res) => {
            console.log('轮播图', res);
            this.slideshowList = res.rows;
        });
    },
    watch: {
        searchValue() {
            this.pageNum = 1;
            this.getData();
        },
        type() {
            this.pageNum = 1;
            this.getData();
        },
    },
    methods: {
        getData() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
            };
            if (this.type !== '') {
                params.label = this.type;
            }
            if (this.searchValue) {
                params.searchValue = this.searchValue;
            }
            this.$hao.getLibraryList(params).then((res) => {
                console.log('场馆列表', res);
                this.list = res.rows;
                this.total = res.total;
            });
        },
        currentChange(val) {
            //滚动到页面顶部
            window.scrollTo(0, 0);
            this.pageNum = val;
            this.getData();
        },
    },
};
</script>

<style lang="less" scoped>
.lbt {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: 24px 0;
}
.box {
    .row {
        display: flex;
        align-items: center;
        padding-top: 24px;
        .left {
            display: flex;
            align-items: center;
            .col {
                display: flex;
                align-items: center;
                margin-right: 24px;
                span {
                    font-size: 13px;
                    color: #333333;
                }
                .el-select {
                    width: 160px;
                }
                ::v-deep .el-input__inner {
                    border: 0;
                }
            }
        }
    }

    .list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-column-gap: 16px;
        width: 100%;

        .item {
            display: flex;
            flex-direction: column;
            page-break-inside: avoid;
            width: 100%;
            background: #ffffff;
            border: 1px solid #ebebeb;
            border-radius: 4px;
            margin-top: 24px;
            position: relative;

            .active {
                width: 100%;
                height: 210px;
            }

            .info {
                padding: 12px;

                .title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 23px;
                    height: 46px;
                    color: #333333;
                    width: 100%;
                    .ellipsisLine(2);
                }
                .status {
                    position: absolute;
                    right: 0;
                    top: 0;
                    font-size: 16px;
                    line-height: 23px;
                    color: #ffffff;
                    padding: 4px 12px;
                    background: #f33f3e;
                    border-radius: 2px 4px;
                }

                .address {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    span {
                        font-size: 13px;
                        line-height: 19px;
                        color: #999999;
                    }
                }
            }
        }
    }
    .pagination {
        margin: 20px auto;
        cursor: pointer;
    }
}
</style>
